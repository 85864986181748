import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { ProfilePageTypes, VerticalRouteType } from '@rma/generic/domain/types';
import { cache } from 'decorator-cache-getter';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BrowseMode } from '../domain';

const RouteRegexConstants: Record<string, RegExp> = {
  [BrowseMode.Finance]: /\/mortgage-broker\/\b/,
  [BrowseMode.Leasing]: /\b\/leasing\/\b/,
  [BrowseMode.Sales]: /\b\/sales|\/real-estate-agent|\/real-estate-agency\b/,
};

@Injectable({
  providedIn: 'root',
})
export class BrowseModeService {
  private sessionStorageKey = 'RMA_TOGGLE_MODE';
  private modeSource: BehaviorSubject<BrowseMode>;

  @cache
  public get mode$(): Observable<BrowseMode> {
    return this.modeSource.asObservable().pipe(distinctUntilChanged());
  }

  constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(SESSION_STORAGE) private readonly sessionStorage: Storage,
  ) {
    this.modeSource = new BehaviorSubject<BrowseMode>(this.getDefaultMode());

    // I am pretty sure that this can be simplified to be a single observable
    // Please see @stones

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
      )
      .subscribe((mode) => this.changeModeForUrl(mode));
  }

  public setMode(mode: BrowseMode): string[] {
    if (this.modeSource.getValue() !== mode) {
      if (this.sessionStorage) {
        this.sessionStorage.setItem(this.sessionStorageKey, mode);
      }
      this.modeSource.next(mode);
    }

    return this.getRoute(mode);
  }

  private changeModeForUrl(url: string) {
    const mode = Object.keys(RouteRegexConstants).find((key) => RouteRegexConstants[key].test(url)) as BrowseMode | undefined;

    if (mode) {
      this.setMode(mode);
    }
  }

  private getDefaultMode(): BrowseMode {
    if (!this.sessionStorage) {
      return BrowseMode.Sales;
    }

    return (this.sessionStorage.getItem(this.sessionStorageKey) as BrowseMode) ?? BrowseMode.Sales;
  }

  private getRoute(mode: BrowseMode): string[] {
    const defaultRoute = ['search'];

    if (mode === BrowseMode.Finance) {
      return defaultRoute;
    }

    const paths = this.document.location.pathname.substring(1).split('/');

    if (paths.length < 3) {
      return defaultRoute;
    }

    const salesOrLeasing = [VerticalRouteType.Sales, VerticalRouteType.Leasing] as string[];

    // try to switch to leasing/sales page if you are in agency profile page
    if (paths[0] === ProfilePageTypes.RealEstateAgency && salesOrLeasing.includes(paths[2]) && paths[3] !== 'property-listings') {
      paths[2] = mode === BrowseMode.Sales ? VerticalRouteType.Sales : VerticalRouteType.Leasing;
      return [`${paths.slice(0, 3).join('/')}/overview`];
    }

    //Handle location pages
    const locationPagesPath = 'real-estate-profile';

    if (paths[0] === locationPagesPath) {
      paths[1] = mode === BrowseMode.Sales ? VerticalRouteType.Sales : VerticalRouteType.Leasing;

      if (paths.length > 3 && ['reviews', 'agencies', 'awards', 'map'].includes(paths[3])) {
        return [paths.slice(0, paths.length).join('/')];
      }

      return [paths.slice(0, 3).join('/')];
    }

    return defaultRoute;
  }
}
